@import 'constants';
@import 'app-constants';
@import 'app-utils';

:root {
  --colorPrimaryRgb: 1, 169, 202;
  --colorPrimary: rgb(var(--colorPrimaryRgb));
  --mdc-plain-tooltip-supporting-text-size: 10px;
}

html {
  min-height: 100%;
}

.html {
  &--scrollbar-hidden {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden; // prevent horizontal scrollbar
  max-width: 100%; // prevent horizontal scrollbar
  // make every text unselectable
}

body,
html {
  width: 100%;
  margin: 0;
  color: @font_color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#uploader {
  display: none;
}

.vertical-space-1 {
  height: 1rem;
}

/* ie/edge - do not display X icon: http://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs */
::-ms-clear {
  display: none;
}

.xlarge-text {
  font: @font-display;
}

.medium-text,
.headline {
  font: @font-headline;
}

.title {
  font: @font-title;
}

.subheader {
  font: @font-subheader;
}

.body,
.text,
.content-row {
  /* it should not be necessary to put this to every single list item cell*/
  font: @font-body;
}

.category {
  font: @font-body;
}

.caption {
  font: @font-caption;
}

.button {
  font: @font-button;
}

.text-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2rem;
}

.avatar-list .avatar-wrapper {
  float: left;
  margin-right: 0.5rem;
}

.bordered {
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
}

.box-shadow {
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.3);
}

/* md-tab styling/fixes (general) */
/* the "body" prefix helps us to override material styles without !important */
body .mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: @color-black;
}

body .mdc-line-ripple .mat-form-field-ripple {
  height: 1px;
}

/* end md-tab styling/fixes (general) */

@media all {
  // PVIP-3422 - overlay is ALWAYS on top!
  .cdk-overlay-container,
  .cdk-overlay-pane {
    z-index: 100000 !important;
  }
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

/* ugly hack to make menus show up...*/
.mat-mdc-menu-panel {
  opacity: 1 !important;
  transform: scale(1, 1) !important;
}

.mat-mdc-progress-bar {
  .mdc-linear-progress__buffer-bar {
    background-color: @color-blue-gray-050 !important;
  }
}

.mat-mdc-radio-button {
  &.mat-mdc-radio-checked .mdc-radio__background::before {
    display: none;
  }

  &:hover .mdc-radio .mdc-radio__background::before {
    display: none;
  }
}

.mat-mdc-menu-item .mat-icon {
  margin-right: 0.8rem !important;
}

.mat-mdc-menu-item.celum-menu-item {
  font: @font-button !important;
  height: 3.2rem;
  line-height: 3.2rem;
  display: flex;
  align-items: center;
}

.mat-mdc-menu-panel.workroom-menu-panel {
  width: 18.3rem;
  box-sizing: border-box;
}

.mat-mdc-menu-submenu-icon {
  color: @color-blue-gray-400 !important;
}

.mat-mdc-button-base {
  & .mat-ripple-element {
    background-color: @color-blue-gray-800 !important;
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    line-height: 3.2rem !important;
    min-height: 3.2rem !important;
    height: 3.2rem;
  }
}

.mat-mdc-option.mdc-list-item.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background-color: rgba(@color-blue-gray-800, 0.12);
}

.mat-mdc-option:hover:not(.mdc-list-item--selected) {
  background-color: rgba(@color-blue-gray-800, 0.08) !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  width: 100%;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.celum-input-form-field .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: translateY(-0.4rem);
}

// Input styles
.celum-inline-input--required:before {
  display: none;
}

.celum-input-form-field--white-bg .mdc-notched-outline {
  background-color: unset !important;
}

.celum-input-form-field {
  margin-top: 0 !important;
}

celum-input-form-field--light-substrate:not(.celum-input-form-field--white-bg) .mdc-notched-outline > * {
  border-color: transparent !important;
  border-width: 0.1rem;
}

celum-inline-form-field-buttons {
  padding-bottom: 0.2rem;
}

.input-label {
  margin-bottom: 0.25em;
}

.celum-input-form-field .mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-form-field-icon-prefix {
  margin-right: 0.2rem !important;
}

.mat-mdc-select-arrow-wrapper {
  height: 1.6rem;
  width: 1.4rem;
  justify-content: center;
}

.mat-mdc-select-trigger .mat-mdc-select-arrow {
  border: solid @color-black;
  border-width: 0 0.13rem 0.13rem 0;
  width: 0;
  height: 0;
  display: inline-block;
  padding: 0.25rem;
  transform: rotate(45deg) !important;

  svg {
    display: none;
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
  color: @color-black !important;
}

.mat-drawer-container {
  background-color: @color-white !important;
  color: @color-blue-gray-900;
}

.mat-drawer {
  background-color: @color-white;
  color: @color-blue-gray-900;
}

.mat-drawer.mat-drawer-push {
  background-color: @color-white;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow:
    0px 8px 10px -5px rgba(38, 50, 56, 0.2),
    0px 16px 24px 2px rgba(38, 50, 56, 0.14),
    0px 6px 30px 5px rgba(38, 50, 56, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(38, 50, 56, 0.12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(38, 50, 56, 0.12);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(38, 50, 56, 0.12);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(38, 50, 56, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper {
  padding: 0 !important;
}

.mat-mdc-menu-content {
  .mat-mdc-menu-item {
    height: 3.2rem;
    line-height: 3.2rem;
    width: 100%;

    &[disabled] {
      opacity: 1 !important;
      color: fade(@color-blue-gray-900, 56%);
    }

    .mat-mdc-menu-item-text {
      height: 100%;
      display: flex;
      align-items: center;

      .mat-mdc-checkbox {
        display: flex;
      }
    }
  }
}

.systembar-avatar_mat-menu {
  .systembar-avatar_mat-divider {
    margin: 0.6rem 0;
  }

  .mat-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .systembar-avatar_select-wrapper {
    margin: 1rem 1.6rem 0.8rem 1.6rem;
    width: 20rem;
  }
}

.snackbar_actions {
  margin-left: 1rem;
}

.snackbar-icon(@background) {
  background: @background;
  margin: -1rem 1rem -1rem -1rem;
  height: calc(100% + 1rem) !important;
  padding: 0 1rem;
  border-bottom-left-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}

.snackbar_info_icon {
  .snackbar-icon(@color-primary);
}

.snackbar_warning_icon {
  .snackbar-icon(@color-red);
}

snackbar {
  height: 3.2rem;
  max-width: 50rem;
  flex: 1 1 auto !important;
}

// Material tabs
.mdc-tab {
  opacity: 1 !important;
  flex-grow: 0 !important;

  &__ripple::before {
    background-color: unset !important;
  }

  &--active {
    &:focus .mdc-tab-indicator__content--underline {
      border-color: unset !important;
    }
  }

  &__content {
    font: @font-body;
    font-weight: unset;
    color: @color-blue-gray-900 !important;
  }

  &__text-label {
    color: unset !important;
  }
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0 !important;
}

.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.snackbar-wrapper {
  flex-direction: column;
  position: fixed;
  z-index: 10000000;
  bottom: 2rem;
  left: 8rem;
  filter: none !important;
  transition:
    width 0.5s,
    left 0.5s;
  min-width: 36rem;
}

.mat-mdc-tooltip {
  color: @color-white;
  background-color: @color-blue-gray-700;
  border-radius: 25% !important;

  .mdc-tooltip__surface {
    max-width: unset;
    word-break: break-word;
  }
}

.snackbar-list {
  flex-direction: column;
}

@media (max-width: 1024px) {
  .snackbar-wrapper {
    bottom: 2rem;
    left: 1.6rem;
  }
}

@media (max-width: 516px) {
  .snackbar-wrapper {
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

/* Scrollbars */
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-track:hover {
  background-color: @color-blue-gray-050;
}

::-webkit-scrollbar-thumb {
  background-color: fade(@color-blue-gray-400, 50);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: fade(@color-blue-gray-400, 50);
}

.celum-square-btn--light-ripple:hover {
  .mat-mdc-focus-indicator {
    background: unset;
  }
}

.mat-mdc-button-base.mdc-button.celum-square-btn--light-ripple:not([disabled]):hover {
  --mat-text-button-state-layer-color: transparent;
}

.dialog {
  user-select: auto !important;
}

// Chip styles for robots
.robot-dialog {
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    display: flex !important;
    align-items: center !important;
  }

  .mdc-evolution-chip__action--primary {
    padding-right: 0.8rem !important;
  }

  .mdc-evolution-chip__graphic {
    padding-left: 0 !important;
  }

  .mat-mdc-chip-focus-overlay {
    display: none;
  }
}

.robot-dialog_content_card--readonly {
  .mdc-evolution-chip__graphic {
    padding-right: 0 !important;
  }

  .mdc-evolution-chip__action--primary {
    padding-right: 0.6rem !important;
  }

  .mdc-evolution-chip__text-label {
    display: flex !important;
    align-items: center !important;
  }
}

.mat-mdc-chip-action-label,
.mdc-evolution-chip__action--primary,
.mdc-evolution-chip__cell--primary {
  &:has(.text-truncator) {
    overflow: hidden !important;
  }
}

.mat-mdc-dialog-surface {
  border-radius: 0 !important;
  position: initial !important; // remove position: relative as we use a lot of position: absolute in our dialogs
}

// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// FIXME fix this in CC celum-avatar component!
.celum-avatar--size-20 {
  line-height: 1rem !important;
  padding-left: unset !important;
  padding-top: unset !important;

  .celum-avatar_top-labels {
    top: 0 !important;
    left: -0.2rem !important;
  }
}

.celum-avatar {
  overflow: unset !important;

  &:hover {
    cursor: default;
  }

  &--size-30 {
    .celum-avatar_top-labels {
      top: 0 !important;
      left: -0.2rem !important;

      .mat-icon {
        height: 1.3rem !important;
        width: 1.3rem !important;
      }
    }
  }
}

// Change the cursor to grabbing to all elements in the document while dragging
html:root body.on-drag * {
  cursor: grabbing;
}

.celum-avatar--interactive:hover {
  cursor: pointer;
}

.light-overlay {
  --mat-text-button-state-layer-color: @color-white !important;
}

button.click-disabled,
celum-menu-item.click-disabled > button {
  opacity: 50%;
  cursor: default;
}

.hidden {
  display: none;
}

.invisible {
  cursor: default;
  pointer-events: none;
  user-select: none;
  opacity: 0;
}

.hidden-menu-trigger {
  visibility: hidden;
  position: fixed;
}

magic-button-page {
  .magic-button-entry.magic-button-page_operation {
    width: 13rem;
  }

  [operation-id='no-people-operation'] {
    display: none;
  }
}

.magic-button-space .magic-button-entry {
  width: auto;
  align-items: unset;
  justify-content: unset;
}

a {
  color: unset;
}

button[fallback-action] {
  display: block;
  margin: 2.5rem auto 0 auto;
  text-transform: uppercase;
  color: @color-white !important;
  background-color: @color-blue-gray-600;
}

// TODO @anyone remove this totally/partially if CC improves handling of operations.length = 0 in future version
.celum-operation-context-menu {
  min-height: 0 !important;

  .mat-mdc-menu-content {
    padding: 0 !important;

    & > div:not(:empty) {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

// removes the hover effect from spans to eliminate double hover effect on button and span
.celum-operation-context-menu {
  operation-menu-item {
    width: 100%;
  }

  span.mat-mdc-menu-item.mat-mdc-focus-indicator {
    &:hover:not([disabled]),
    &.cdk-program-focused:not([disabled]),
    &.cdk-keyboard-focused:not([disabled]),
    &.mat-mdc-menu-item-highlighted:not([disabled]) {
      background: transparent;
    }
  }
}

.mat-calendar {
  .mat-mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
  }

  .mat-mdc-icon-button:disabled {
    color: fade(@color-blue-gray-900, 56%);
  }
}

.mat-mdc-option-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-checkbox {
  .mdc-label {
    padding-left: 0.8rem !important;
  }

  .mdc-form-field:hover {
    .mdc-checkbox__ripple {
      visibility: hidden;
    }
  }

  .mdc-checkbox:hover {
    .mdc-checkbox__ripple {
      visibility: visible;
    }
  }
}

.sharing-dialog {
  .mat-mdc-text-field-wrapper {
    margin-top: 0.25em;
  }

  .mdc-switch {
    width: 2.8rem;

    .mdc-switch__handle {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .sharing-dialog_message-textarea {
    .mat-mdc-form-field-infix {
      border-top: 0.6rem solid transparent;
      padding: 0.23em 0 0.7rem 0 !important;
    }
  }
}

// moz-appearance is only supported by firefox, so we can put styles in here in case any other major browser is used (they support webkit-scrollbar)
// TODO: remove the variables, this whole block and thumb-color once we can consume it from CC
@clm-native-scrollbar-track-color: hsl(212, 33.3%, 88.8%);
@clm-native-scrollbar-track-hover-color: hsl(212, 33.3%, 88.8%);
@clm-native-scrollbar-thumb-color: hsl(209, 23.2%, 60.2%);
@clm-native-scrollbar-thumb-hover-color: hsl(210, 21.6%, 49%);
@clm-native-scrollbar-track-border-radius: 0;
// prettier-ignore
@supports not (-moz-appearance:none) {
  .celum-scrollbar,
  .celum-scrollbar--dark {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: .8rem;
      height: .8rem;
      background-color: var(@clm-native-scrollbar-track-color, transparent);
      transition: background-color 0.2s ease;
      border-radius: var(@clm-native-scrollbar-track-border-radius, .8rem);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: .8rem;
    }

    &::-webkit-scrollbar-corner {
      background: transparent; // if both horizontal and vertical scrollbars are active in dark mode, corner would be white
    }
  }

  .celum-scrollbar {
    &::-webkit-scrollbar:hover {
      background-color: var(@clm-native-scrollbar-track-hover-color, @color-blue-gray-050);
    }

    .thumb-color(
      var(@clm-native-scrollbar-thumb-color, fade(@color-blue-gray-400, 50)),
      var(@clm-native-scrollbar-thumb-hover-color, fade(@color-blue-gray-400, 70))
    );
  }

  .celum-scrollbar--dark {
    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar:hover {
      background-color: fade(black, 30);
    }

    .thumb-color(fade(white, 50), fade(white, 70));
  }
}

.thumb-color(@color, @hoverColor) {
  &::-webkit-scrollbar-thumb {
    background: @color;

    &:horizontal {
      background: @color;
    }

    &:hover {
      background: @hoverColor;

      &:horizontal {
        background: @hoverColor;
      }
    }
  }
}
